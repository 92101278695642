import { contactFilterTitles } from 'modules/contacts/constants/contact-filter-titles'
import { ContactSlugEnum } from 'modules/contacts/enums/contact-enum'
import { useContactFields } from 'modules/contacts/hooks/use-contact-fields'
import { SubstituteInterface } from 'shared/components/dashboard-mail-editor/components/substitutes-block'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

type SubstitutionData = { label: string; data: string }

export const useSubstitutes = (
  withoutSubstitutes: boolean = false,
  additionalSubstitutes: SubstituteInterface[] = [],
) => {
  const { t } = useLocoTranslation()

  const { fields: substitutes } = useContactFields(!withoutSubstitutes)

  const additionalSubstitutionData: SubstitutionData[] = additionalSubstitutes.map(el => ({
    label: el.name,
    data: `{${el.slug}}`,
  }))

  const contactSubstitutionData: SubstitutionData[] = (substitutes?.items ?? []).map(el => ({
    label:
      el.slug in ContactSlugEnum
        ? t(contactFilterTitles[el.slug as ContactSlugEnum])
        : el.fieldName,
    data: `{${el.slug}}`,
  }))

  const affiliateSubstitutionData: SubstitutionData[] = [
    { label: t('dashboard.email.contact_affiliate_id'), data: '{affiliate_id}' },
    { label: t('dashboard.email.contact_affiliate_url'), data: '{affiliate_dashboard}' },
  ]

  const substitutionData = [
    ...additionalSubstitutionData,
    ...contactSubstitutionData,
    ...affiliateSubstitutionData,
  ]

  const isReady = !!substitutes || withoutSubstitutes

  return {
    substitutionData,
    isReady,
  }
}
