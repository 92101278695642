import { ContactExportInterface } from 'modules/contacts/types/contact-export-interface'
import { ContactFilterSavedInterface } from 'modules/contacts/types/contact-filter-interface'
import { ContactEditInterface, ContactInterface } from 'modules/contacts/types/contact-interface'
import {
  ContactBulkCampaignInterface,
  ContactBulkInterface,
  ContactBulkTagInterface,
} from 'modules/contacts/types/contact-menu-interface'
import {
  FetcherType,
  RequestMethodsEnum,
} from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import httpClient from 'shared/utils/http-client'

export const CONTACT_API = '/api/dashboard/customer/contact'
export const CONTACT_ASSISTANT_API = '/api/dashboard/assistant/contact'
export const CONTACT_EXTRA_INFO_API = `/api/dashboard/contact`

export const createContact = async (data: ContactEditInterface) =>
  httpClient.post<ContactInterface>(`${CONTACT_API}/contacts`, data)

export const deleteContact = async (id: number) =>
  httpClient.delete(`${CONTACT_API}/contacts/${id}`)

export const updateContact = async (id: number, data: Partial<ContactEditInterface>) =>
  httpClient.patch<ContactInterface>(`${CONTACT_API}/contacts/${id}`, data)

export const addTag = async (data: ContactBulkTagInterface) =>
  httpClient.post(`${CONTACT_API}/bulk-actions/add-tag`, data)

export const removeTag = async (data: ContactBulkTagInterface) =>
  httpClient.post(`${CONTACT_API}/bulk-actions/remove-tag`, data)

export const subscribeToCampaign = async (data: ContactBulkCampaignInterface) =>
  httpClient.post(`${CONTACT_API}/bulk-actions/subscribe-to-campaign`, data)

export const unsubscribeFromCampaign = async (data: ContactBulkCampaignInterface) =>
  httpClient.post(`${CONTACT_API}/bulk-actions/unsubscribe-from-campaign`, data)

export const unsubscribeEmail = async (data: ContactBulkInterface) =>
  httpClient.post(`${CONTACT_API}/bulk-actions/unsubscribe-from-emails`, data)

export const resubscribeEmail = async (data: ContactBulkInterface) =>
  httpClient.post(`${CONTACT_API}/bulk-actions/resubscribe-to-emails`, data)

export const deleteContacts = async (data: ContactBulkInterface) =>
  httpClient.post(`${CONTACT_API}/bulk-actions/bulk-remove`, data)

export const deleteSavedFilter = async (id: number) =>
  httpClient.delete(`${CONTACT_API}/filters/${id}`)

export const saveNewFilter = async (data: Omit<ContactFilterSavedInterface, 'id'>) =>
  httpClient.post<ContactFilterSavedInterface>(`${CONTACT_API}/filters`, data)

export const exportContacts = async (
  data: ContactExportInterface,
  fetcher: FetcherType<RequestMethodsEnum.post>,
) => fetcher(`${CONTACT_API}/bulk-actions/export`, data)
