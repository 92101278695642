export enum CkeditorPluginsEnum {
  alignment = 'Alignment',
  autoformat = 'Autoformat',
  blockquote = 'BlockQuote',
  bold = 'Bold',
  strikethrough = 'Strikethrough',
  cloudServices = 'CloudServices',
  essentials = 'Essentials',
  fontFamily = 'FontFamily',
  fontBackgroundColor = 'FontBackgroundColor',
  fontColor = 'FontColor',
  fontSize = 'FontSize',
  heading = 'Heading',
  image = 'Image',
  imageCaption = 'ImageCaption',
  imageStyle = 'ImageStyle',
  imageInsert = 'ImageInsert',
  imageToolbar = 'ImageToolbar',
  imageUpload = 'ImageUpload',
  imageResize = 'ImageResize',
  indent = 'Indent',
  indentBlock = 'IndentBlock',
  italic = 'Italic',
  link = 'Link',
  linkImage = 'LinkImage',
  documentList = 'DocumentList',
  paragraph = 'Paragraph',
  pasteFromOffice = 'PasteFromOffice',
  simpleUploadAdapter = 'SimpleUploadAdapter',
  specialCharacters = 'SpecialCharacters',
  specialCharactersCurrency = 'SpecialCharactersCurrency',
  specialCharactersText = 'SpecialCharactersText',
  table = 'Table',
  TextTransformation = 'TextTransformation',
  tableToolbar = 'TableToolbar',
  underline = 'Underline',
  substitution = 'substitution',
}
