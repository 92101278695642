import { CONTACT_API } from 'modules/contacts/api/contact-api'
import { ContactSlugEnum } from 'modules/contacts/enums/contact-enum'
import { ContactFieldApiInterface } from 'modules/contacts/types/contact-filter-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

export function useContactFields(shouldFetch: boolean = true) {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    ContactFieldApiInterface
  >({
    method: RequestMethodsEnum.get,
    afterFetch: async data => {
      return {
        ...data,
        items: [
          ...data.items.sort((a, b) => {
            if (!(a.slug in ContactSlugEnum)) {
              return 1
            }
            if (!(b.slug in ContactSlugEnum)) {
              return -1
            }
            const keys = Object.keys(ContactSlugEnum)
            return keys.indexOf(a.slug) - keys.indexOf(b.slug)
          }),
        ],
      }
    },
  })

  const { data: fields, mutate: mutateFields } = useSWR(() => {
    if (user && shouldFetch) {
      return `${CONTACT_API}/fields/list?pagination[limit]=50`
    }
    return null
  }, fetcher)

  return { fields, mutateFields }
}
